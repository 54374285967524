// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@include mat.core();

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './custom-palettes.scss';
@import 'swiper/swiper-bundle.css';
@import '../src/assets/css/font-awesome-pro.css';
@import '../src/assets/css/elegant-icon.css';
@import '../src/assets/scss/main.scss';














html,
body {
  height: 100%;
}

body {
  @apply p-0 m-0 font-primary box-border;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  @apply inline-block;
}

button.mat-raised-button,
button.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

.cashier-salereturns-table-checkbox {
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    @apply bg-themeBlue;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: 'Nunito Sans', sans-serif !important;
}






/* Plain buttons */
.custom-button {
  @apply flex items-center gap-2 h-[2.25rem] transition outline-none py-2 px-4 text-base border font-semibold whitespace-nowrap;
  @apply border-zinc-200 py-2 px-4 text-zinc-500 focus:outline-2 focus:outline-zinc-100 hover:bg-zinc-100 active:bg-zinc-300 disabled:bg-white disabled:border-zinc-200 disabled:text-zinc-200;

  &.custom-button-primary {
    @apply rounded-sm border-red-500 text-red-500 py-2 px-4 focus:outline-2 focus:outline-red-100 hover:bg-themeLightRed hover:text-white active:bg-red-400 active:text-white disabled:bg-white disabled:border-red-300 disabled:text-red-300;
  }

  &.custom-button-primary-filled {
    @apply rounded-sm border-red-500 py-2 px-4 text-white bg-themeRed focus:outline-2 focus:outline-red-100 hover:bg-themeLightRed active:bg-themeDarkRed disabled:bg-red-100 disabled:text-red-300 disabled:border-red-300;
  }

  &.custom-button-success {
    @apply rounded-sm border-green-500 text-green-500 py-2 px-4 focus:outline-2 focus:outline-green-100 hover:bg-themeLightGreen hover:text-white active:bg-green-400 active:text-white disabled:bg-white disabled:border-green-300 disabled:text-green-300;
  }

  &.custom-button-success-filled {
    @apply rounded-sm border-green-500 py-2 px-4 text-white bg-themeGreen focus:outline-2 focus:outline-green-100 hover:bg-themeLightGreen active:bg-themeDarkGreen disabled:bg-green-100 disabled:text-green-300 disabled:border-green-300;
  }

  &.custom-button-info {
    @apply rounded-sm border-sky-500 text-sky-500 py-2 px-4 focus:outline-2 focus:outline-sky-100 hover:bg-sky-400 hover:text-white active:bg-sky-400 active:text-white disabled:bg-white disabled:border-sky-400 disabled:text-sky-400;
  }

  &.custom-button-info-filled {
    @apply rounded-sm border-sky-500 py-2 px-4 text-white bg-sky-400 focus:outline-2 focus:outline-sky-100 hover:bg-sky-300 active:bg-sky-500 disabled:bg-sky-100 disabled:text-sky-300 disabled:border-sky-300;
  }
}

.custom-button-sm {
  @apply flex items-center gap-2 h-[1.75rem] transition outline-none py-1 px-2 text-sm border font-semibold whitespace-nowrap;
  @apply border-zinc-200 py-2 px-4 text-zinc-500 hover:bg-zinc-100 active:bg-zinc-300 disabled:bg-white disabled:border-zinc-200 disabled:text-zinc-200;

  &.custom-button-primary {
    @apply rounded-sm border-red-500 text-red-500 py-1 px-2 focus:outline-2 focus:outline-red-100 hover:bg-themeLightRed hover:text-white active:bg-red-400 active:text-white disabled:bg-white disabled:border-red-300 disabled:text-red-300;
  }

  &.custom-button-primary-filled {
    @apply rounded-sm border-red-500 py-1 px-2 text-white bg-themeRed focus:outline-2 focus:outline-red-100 hover:bg-themeLightRed active:bg-themeDarkRed disabled:bg-red-100 disabled:text-red-300 disabled:border-red-300;
  }

  &.custom-button-success {
    @apply rounded-sm border-green-500 text-green-500 py-1 px-2 focus:outline-2 focus:outline-green-100 hover:bg-themeLightGreen hover:text-white active:bg-green-400 active:text-white disabled:bg-white disabled:border-green-300 disabled:text-green-300;
  }

  &.custom-button-success-filled {
    @apply rounded-sm border-green-500 py-1 px-2 text-white bg-themeGreen focus:outline-2 focus:outline-green-100 hover:bg-themeLightGreen active:bg-themeDarkRed disabled:bg-green-100 disabled:text-green-300 disabled:border-green-300;
  }

  &.custom-button-info {
    @apply rounded-sm border-sky-500 text-sky-500 py-2 px-4 focus:outline-2 focus:outline-sky-100 hover:bg-sky-400 hover:text-white active:bg-sky-400 active:text-white disabled:bg-white disabled:border-sky-400 disabled:text-sky-400;
  }

  &.custom-button-info-filled {
    @apply rounded-sm border-sky-500 py-2 px-4 text-white bg-sky-400 focus:outline-2 focus:outline-sky-100 hover:bg-sky-300 active:bg-sky-500 disabled:bg-sky-100 disabled:text-sky-300 disabled:border-sky-300;
  }
}



.custom-button-close {
  @apply block rounded-full w-[35px] h-[35px] p-1 hover:bg-slate-100 active:bg-slate-300 outline-none transition;
}





.card {
  @apply bg-white border border-gray-200 w-full shadow rounded-sm;

  .card-header {
    @apply pt-6 px-6 text-2xl font-semibold mb-4
  }

  .card-body {
    @apply px-6
  }

  .card-footer {
    @apply pb-6 px-6 mt-4
  }
}

.white-container {
  @apply bg-white p-4 mb-10;
}





.input-block-checkbox {
  @apply flex items-center;
}

.form-checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500;
}

.form-checkbox+.form-label {
  @apply ms-2 text-sm font-medium;
}


/* START - this will style and make a functionality for all input checkbox inside tables */

td.checker-item,
th.checker-item {
  @apply sticky left-0 z-[1];
}

.input-checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2
}

/* END */




.form-label {
  @apply block mb-2 text-sm font-medium text-gray-900;
}

.form-control {
  @apply text-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-0 focus:ring-slate-400 focus:border-slate-400 block w-full p-2.5 rounded-sm min-h-[46px];

  .mat-mdc-select-min-line {
    @apply text-sm;
  }
}





.form-title {
  @apply text-xl font-semibold;
}

.form-group {
  @apply flex gap-2 justify-between items-center text-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-0 focus:ring-slate-400 focus:border-slate-400 block w-full p-2.5 rounded-sm min-h-[46px];

  .form-group-input {
    @apply grow-[1];
    @apply outline-none bg-transparent w-full;
  }

  .form-group-label {
    @apply shrink-[1];
  }
}





.form-group-reverse {
  @apply flex flex-row-reverse gap-2 justify-between items-center text-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-0 focus:ring-slate-400 focus:border-slate-400 block w-full p-2.5 rounded-sm;

  .form-group-input {
    @apply grow-[1];

    .form-control-def {
      @apply bg-transparent outline-none w-full;
    }
  }

  .form-group-label {
    @apply shrink-[1];
  }

}

.form-submit {
  @apply flex flex-row-reverse gap-4;
}


.table-responsive {
  @apply relative overflow-x-auto;
}

table {
  &.custom-table {

    @apply w-full text-sm text-left rtl:text-right border;

    &.custom-table-border,
    &.bordered,
    &.table-bordered {
      td {
        @apply border-b;
      }
    }

    &.custom-table-border-row {
      td {
        @apply border-b;
      }
    }

    &.custom-table-default {
      thead tr th {
        @apply bg-gray-50;
      }
    }

    &.custom-table-primary {
      thead tr th {
        @apply bg-red-600 text-white;
      }
    }

    thead tr th {
      @apply text-sm whitespace-nowrap px-6 py-4 border-b;
    }

    tbody tr td {
      @apply px-6 py-2;
    }
  }

  &.custom-table-sm {

    @apply w-full text-sm text-left rtl:text-right border;

    &.custom-table-border,
    &.bordered,
    &.table-bordered {
      td {
        @apply border-b;
      }
    }

    &.custom-table-border-row {
      td {
        @apply border-b;
      }
    }

    &.custom-table-default {
      thead tr th {
        @apply bg-gray-50 text-sm;
      }
    }

    &.custom-table-primary {
      thead tr th {
        @apply bg-red-600 text-white;
      }
    }

    thead tr th {
      @apply text-sm whitespace-nowrap px-3 py-2 text-sm border-b;
    }

    tbody tr td {
      @apply px-3 py-1 text-sm;
    }
  }
}

.link {
  @apply hover:underline;
}

.text-muted {
  @apply text-zinc-400;
}




.flex-start {
  @apply flex justify-start items-center;
}

.flex-end {
  @apply flex justify-end items-center;
}

.flex-centered {
  @apply flex justify-center items-center;
}

.alert {
  @apply p-4 mb-4 text-sm;

  &.alert-info {
    @apply text-blue-800 border-s-4 border-t border-e border-b border-s-blue-500 bg-blue-50;
  }

  &.alert-danger {
    @apply text-red-800 border-s-4 border-t border-e border-b border-s-red-500 bg-red-50;
  }

  &.alert-success {
    @apply text-green-800 border-s-4 border-t border-e border-b border-s-green-500 bg-green-50;
  }

  &.alert-warning {
    @apply text-yellow-800 border-s-4 border-t border-e border-b border-s-yellow-500 bg-yellow-50;
  }

  &.alert-light {
    @apply text-gray-800 border-s-4 border-t border-e border-b border-s-gray-500 bg-gray-50;
  }
}

.pill {
  @apply rounded-full border drop-shadow-sm px-3 p-1 whitespace-nowrap;


  &.pill-info {
    @apply text-blue-800 bg-blue-50;
  }

  &.pill-danger {
    @apply text-red-800 bg-red-50;
  }

  &.pill-success {
    @apply text-green-800 bg-green-50;
  }

  &.pill-warning {
    @apply text-yellow-800 bg-yellow-50;
  }

  &.pill-light {
    @apply text-gray-800 bg-gray-50;
  }
}

.badge {

  &.badge-default {
    @apply bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-dark {
    @apply bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-red {
    @apply bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-green {
    @apply bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-yellow {
    @apply bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-indigo {
    @apply bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-purple {
    @apply bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

  &.badge-pink {
    @apply bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm;
  }

}



ul.nav-tabs {
  @apply flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 bg-white border mb-4;

  li.nav-item {
    @apply me-2;

    a.nav-link {
      @apply inline-block p-4 cursor-pointer hover:text-gray-600 hover:bg-gray-50;

    }

    a.nav-link.active {
      @apply inline-block p-4 text-red-400 bg-gray-100;
    }
  }
}




.print-data-true {
  display: none;
}

@media print {
  .print-data-true {
    display: initial;
  }

  .print-data-false {
    display: none;
  }

  .bg-bodyBg {
    background-color: white;
  }
}



/* Deprecated! Avoid using this. */

.form-control-group-input {
  @apply text-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-0 focus:ring-slate-400 focus:border-slate-400 block w-full p-2.5 ps-10 rounded-sm;
}

.form-control-group {
  @apply text-sm relative;
}

.form-control-group-label {
  @apply text-sm absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none;
}

/* End Of Deprecated */




.loader-container {
  @apply m-5 p-5 flex justify-center w-full;
}

.loader {
  box-sizing: border-box;
  position: relative;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}

.loader:after,
.loader:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  background: #FF3D00;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: spin 1s linear infinite;
  transform-origin: 0px 100%;
}

.loader:before {
  transform-origin: 0 50%;
  background: transparent;
  border: 1px #FF3D00 solid;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}